<template>
  <v-row class="mb-16">
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true"></mini-header>
    <!-- end header -->
    <v-col cols="12" class="pa-0 pb-10">
      <div class="d-flex">
        <div cols="6" class="pa-0" style="flex: 1">
          <v-card flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
                <v-row align="center" no-gutters>
                  <v-icon size="18" left color="secondary">mdi-page-layout-sidebar-right</v-icon>
                  Configuración del layout
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pt-0 pb-3">
              <v-col cols="4" class="px-2">
                <span class="body-1 text--secondary font-weight-medium">Color de fondo</span>
                <v-sheet class="mt-3" width="140">
                  <v-text-field v-model="logo.color" outlined required single-line dense hide-details>
                    <template v-slot:append>
                      <v-menu v-model="menu" bottom :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="logStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="logo.color" flat width="250" />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-sheet>
              </v-col>

              <v-row class="px-2 my-5" no-gutters>
                <v-col cols="3" class="body-1 d-flex align-center pr-0">
                  Logotipo
                  <v-icon color="secondary" small right>mdi-information</v-icon>
                </v-col>
                <v-col class="pl-0">
                  <v-card class="upload-logo" flat height="95" outlined @click="$refs['logo'].click()">
                    <v-card-text :class="logo.file ? 'text-center pa-2' : 'pa-2'">
                      <input ref="logo" type="file" accept="image/jpeg, image/png" style="display:none" @change="getLogoPreview1">
                      <img v-if="logo.file" :src="logo.file" :height="80" />
                      <span class="mt-0 mx-auto text-center transparent" v-else style="position: absolute; z-index: 0; left: 0px; right: 0px">
                      <v-icon size="36" color="secondary">mdi-cloud-upload-outline</v-icon>
                      <span class="d-block my-1 body-1 fontBody--text font-weight-semibold">Haz click para seleccionar un archivo </span>
                      <span class="d-block body-2 mt-n1">o arrastra el archivo aquí (jpg, png)</span>
                    </span>
                  </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-col cols="12" class="mt-2 pl-0">
                <v-checkbox v-model="logo.hiddenLogo" label="Sin logotipo" hide-details dense />
              </v-col>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-5 py-2">
              <v-spacer />
              <v-btn class="px-4" outlined>Cancelar</v-btn>
              <v-btn class="px-4" color="primary">Guardar</v-btn>
            </v-card-actions>
          </v-card>

          <v-card flat class="mt-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
                <v-row align="center" no-gutters>
                  <v-icon size="18" left color="secondary">mdi-format-text</v-icon>
                  Configuración de los títulos y subtítulos
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pt-6 pb-0 px-0">
                <!-- alert -->
                <v-alert class="inf-alert-blue primary--text rounded-md mt-0 mx-4" dense text>
                <div class="d-flex pa-0 my-1">
                  <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4">
                    <span class="body-2" style="line-height: 14px;">Para
                      <span class="font-weight-bold">modificar el contenido del botón, haz click en el texto,</span> y comienza a editar. Si deseas
                      <span class="font-weight-bold">moverlo o eliminarlo</span> debes
                      <span class="font-weight-bold">seleccionar</span> el
                      <span class="font-weight-bold">icono correspondiente.</span>
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
              <v-col cols="12" class="my-5 py-1 px-5 d-flex align-center">
                <span class="subtitle-2 secondary--text font-weight-semibold">Título</span>
              </v-col>
              <v-row class="px-5 mb-2">
                <v-col class="pt-0">
                  <v-row class="ma-0" no-gutters align="center">
                    <v-col cols="4" class="py-1 pr-2">
                      <span class="body-1">Fuente</span>
                      <v-autocomplete :items="[]"  hide-details single-line dense outlined hide-no-data />
                    </v-col>
                    <v-col class="py-1 pr-2 ml-2">
                      <span class="body-1">Tamaño</span>
                      <v-autocomplete :items="lengthList" hide-details single-line dense outlined hide-no-data />
                    </v-col>
                    <v-col class="py-1 pr-2 ml-2">
                      <span class="body-1">Color de la fuente</span>
                      <v-sheet width="140">
                        <v-text-field v-model="text.fontColorTitle" outlined required single-line dense hide-details>
                          <template v-slot:append>
                            <v-menu v-model="menuFontColorTitle" bottom :close-on-content-click="false">
                              <template v-slot:activator="{ on }">
                                <div :style="swatchStyleFontColorTitle" v-on="on" />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="text.fontColorTitle" flat width="250" />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider />
              <v-col cols="12" class="my-5 py-1 px-5 d-flex align-center">
                <span class="subtitle-2 secondary--text font-weight-semibold">Subtítulo</span>
              </v-col>
              <v-row class="px-5 mb-2">
                <v-col class="pt-0">
                  <v-row class="ma-0" no-gutters align="center">
                    <v-col cols="4" class="py-1 pr-2">
                      <span class="body-1">Fuente</span>
                      <v-autocomplete :items="[]" hide-details single-line dense outlined hide-no-data />
                    </v-col>
                    <v-col class="py-1 pr-2 ml-2">
                      <span class="body-1">Tamaño</span>
                      <v-autocomplete :items="lengthList" hide-details single-line dense outlined hide-no-data />
                    </v-col>
                    <v-col class="py-1 pr-2 ml-2">
                      <span class="body-1">Color de la fuente</span>
                      <v-sheet width="140">
                        <v-text-field v-model="text.fontColorSubtitle" outlined required single-line dense hide-details>
                          <template v-slot:append>
                            <v-menu v-model="menuFontColorSubtitle" bottom :close-on-content-click="false">
                              <template v-slot:activator="{ on }">
                                <div :style="swatchStyleFontColorSubtitle" v-on="on" />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="text.fontColorSubtitle" flat width="250" />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider />
              <v-col cols="12" class="my-5 py-1 px-5 d-flex align-center">
                <span class="subtitle-2 secondary--text font-weight-semibold">Párrafos</span>
              </v-col>
              <v-row class="px-5 mb-2">
                <v-col class="pt-0">
                  <v-row class="ma-0" no-gutters align="center">
                    <v-col cols="4" class="py-1 pr-2">
                      <span class="body-1">Fuente</span>
                      <v-autocomplete :items="[]" hide-details single-line dense outlined hide-no-data />
                    </v-col>
                    <v-col class="py-1 pr-2 ml-2">
                      <span class="body-1">Tamaño</span>
                      <v-autocomplete :items="lengthList" hide-details single-line dense outlined hide-no-data />
                    </v-col>
                    <v-col class="py-1 pr-2 ml-2">
                      <span class="body-1">Color de la fuente</span>
                      <v-sheet width="140">
                        <v-text-field v-model="text.fontColorParagraph" outlined required single-line dense hide-details>
                          <template v-slot:append>
                            <v-menu v-model="menuFontColorParagraph" bottom :close-on-content-click="false">
                              <template v-slot:activator="{ on }">
                                <div :style="swatchStyleFontColorParagraph" v-on="on" />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="text.fontColorParagraph" flat width="250" />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-5 py-2">
              <v-spacer />
              <v-btn class="px-4" outlined>Cancelar</v-btn>
              <v-btn class="px-4" color="primary">Guardar</v-btn>
            </v-card-actions>
          </v-card>

          <v-card flat class="mt-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
                <v-row align="center" no-gutters>
                  <v-icon size="18" left color="secondary">mdi-image</v-icon>
                  Configuración de las imagenes
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pt-5 pb-0 px-0">
              <!-- alert -->
              <v-alert class="inf-alert-blue primary--text rounded-md mt-0 mx-4" dense text>
                <div class="d-flex pa-0 my-1">
                  <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4">
                    <span class="body-2" style="line-height: 14px;">Para
                      <span class="font-weight-bold">modificar las imágenes, parate sobre ellas,</span> y comienza a editar. Si deseas
                      <span class="font-weight-bold">moverlo o eliminarlo</span> debes
                      <span class="font-weight-bold">seleccionar</span> el
                      <span class="font-weight-bold">icono correspondiente.</span>
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->

              <v-row class="px-5 mb-2 mt-5">
                <v-col cols="12" class="pt-0">
                  <v-row class="ma-0" no-gutters>
                    <v-col class="pr-2 py-0" style="max-width: 160px">
                      <span class="body-1">Color del borde</span>
                      <v-sheet width="140">
                        <v-text-field v-model="image.borderColor" :disabled="image.hiddenBorder" outlined required single-line dense hide-details>
                          <template v-slot:append>
                            <v-menu v-model="menu8" bottom :close-on-content-click="false">
                              <template v-slot:activator="{ on }">
                                <div :style="swatchImage" v-on="on" />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="image.borderColor" :disabled="image.hiddenBorder" flat width="250" />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-sheet>
                      <v-checkbox v-model="image.hiddenBorder" class="mt-1" label="Sin borde" hide-details dense />
                    </v-col>
                    <v-col class="pa-0" style="max-width: 200px">
                      <span class="d-block body-1 mb-1">Ancho del borde</span>
                      <v-sheet max-width="190">
                        <div class="d-flex align-center">
                          <v-slider v-model="image.borderWidth" :max="20" color="primary" :disabled="image.hiddenBorder" hide-details />
                          <span class="secondary--text body-2 ml-1" style="min-width: 35px;">{{ image.borderWidth }}px</span>
                        </div>
                     </v-sheet>
                    </v-col>
                    <v-col class="py-0">
                      <span class="d-block body-1 mb-2">Forma de la esquina</span>
                      <v-card class="rounded-sm pa-0 ma-0" flat outlined width="152" :disabled="image.hiddenBorder">
                        <v-row class="fill-height ma-0 pa-0" align="center">
                          <v-card class="text-center align-center pt-2 pb-1 px-0 rounded-0 transparent" flat @click="borderSelected = 'sm'" :class="{'primary rounded-l-sm' : borderSelected === 'sm'}" width="50" :height="34">
                            <img :src="require(`@/assets/product-outbound/portal/border-1${borderSelected === 'sm' ? '--active' : ''}.svg`)" />
                          </v-card>
                          <v-card class="text-center align-center pt-2 pb-1 px-0 rounded-0 transparent" flat @click="borderSelected = 'md'" :class="{'primary' : borderSelected === 'md'}" width="50" :height="34">
                            <img :src="require(`@/assets/product-outbound/portal/border-2${borderSelected === 'md' ? '--active' : ''}.svg`)" />
                          </v-card>
                          <v-card class="text-center align-center pt-2 pb-1 px-0 rounded-0 transparent" flat @click="borderSelected = 'lg'" :class="{'primary rounded-r-sm' : borderSelected === 'lg'}" width="50" :height="34">
                            <img :src="require(`@/assets/product-outbound/portal/border-3${borderSelected === 'lg' ? '--active' : ''}.svg`)" />
                          </v-card>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-5 py-2">
              <v-spacer />
              <v-btn class="px-4" outlined>Cancelar</v-btn>
              <v-btn class="px-4" color="primary">Guardar</v-btn>
            </v-card-actions>
          </v-card>

          <v-card flat class="mt-5">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
                <v-row align="center" no-gutters>
                  <v-icon size="18" left color="secondary">mdi-button-cursor</v-icon>
                  Configuración de los botones
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pt-5 pb-0 px-0">
              <!-- alert -->
              <v-alert class="inf-alert-blue primary--text rounded-md mt-0 mx-4" dense text>
                <div class="d-flex pa-0 my-1">
                  <v-icon color="primary" size="22">mdi-information-outline</v-icon>
                  <div class="ml-4">
                    <span class="body-2" style="line-height: 14px;">Para
                      <span class="font-weight-bold">modificar el contenido del botón, haz click en el texto,</span> y comienza a editar. Si deseas
                      <span class="font-weight-bold">moverlo o eliminarlo</span> debes
                      <span class="font-weight-bold">seleccionar</span> el
                      <span class="font-weight-bold">icono correspondiente.</span>
                    </span>
                  </div>
                </div>
              </v-alert>
              <!-- end alert -->
              <v-row class="px-5">
                <v-col class="pt-0">
                  <v-row class="ma-0" no-gutters align="center">
                    <v-col cols="6" class="py-1 pr-2">
                      <span class="body-1">Fuente</span>
                      <v-autocomplete :items="[]" hide-details single-line dense outlined hide-no-data />
                    </v-col>
                    <v-col cols="4" class="py-1 pr-2 ml-2">
                      <span class="body-1">Color de la fuente</span>
                      <v-sheet width="140">
                        <v-text-field v-model="btn.fontColor" outlined required single-line dense hide-details>
                          <template v-slot:append>
                            <v-menu v-model="btnMenuFontColor" bottom :close-on-content-click="false">
                              <template v-slot:activator="{ on }">
                                <div :style="btnFontColor" v-on="on" />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="btn.fontColor" flat width="250" />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="px-5 mb-2">
                <v-col class="pt-0">
                  <v-row class="ma-0" no-gutters align="center">
                    <v-col cols="4" class="py-0">
                      <span class="body-1">Color de relleno</span>
                      <v-sheet width="140">
                        <v-text-field v-model="btn.backgroundColor" :disabled="btn.hiddenBackgroundColor" outlined required single-line dense hide-details>
                          <template v-slot:append>
                            <v-menu v-model="menuBtnFontColor" bottom :close-on-content-click="false">
                              <template v-slot:activator="{ on }">
                                <div :style="swatchStyleBtnBackgroundColor" v-on="on" />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="btn.backgroundColor" :disabled="btn.hiddenBackgroundColor" flat width="250" />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-sheet>
                      <v-checkbox v-model="btn.hiddenBackgroundColor" class="mt-1" label="Sin relleno" hide-details dense />
                    </v-col>
                    <v-col cols="4" class="py-0 pl-2">
                      <span class="body-1">Color del borde</span>
                      <v-sheet width="140">
                        <v-text-field v-model="btn.borderColor" :disabled="btn.hiddenBorder" outlined required single-line dense hide-details>
                          <template v-slot:append>
                            <v-menu v-model="menuBtnBackgroundColor" bottom :close-on-content-click="false">
                              <template v-slot:activator="{ on }">
                                <div :style="swatchStyleBtnBorderColor" v-on="on" />
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="btn.borderColor" :disabled="btn.hiddenBorder" flat width="250" />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-sheet>
                      <v-checkbox v-model="btn.hiddenBorder" class="mt-1" label="Sin borde" hide-details dense />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="px-5 pb-5">
                <v-col class="pt-0" style="max-width: 200px">
                  <span class="d-block body-1 mb-1">Ancho del borde</span>
                  <v-sheet max-width="200">
                    <div class="d-flex align-center">
                      <v-slider v-model="btn.borderWidth" :max="20" color="primary" :disabled="btn.hiddenBorder" hide-details />
                      <span class="secondary--text body-2 ml-1" style="min-width: 35px;">{{ btn.borderWidth }}px</span>
                    </div>
                  </v-sheet>
                </v-col>
                <v-col class="pt-0" style="max-width: 180px">
                  <span class="d-block body-1 mb-2">Forma de la esquina</span>
                  <v-card class="rounded-sm pa-0 ma-0" flat outlined width="152">
                    <v-row class="fill-height ma-0 pa-0" align="center">
                      <v-card class="text-center align-center pt-2 pb-1 px-0 rounded-0 transparent" flat @click="borderSelected = 'sm'" :class="{'primary rounded-l-sm' : borderSelected === 'sm'}" width="50" :height="34">
                        <img :src="require(`@/assets/product-outbound/portal/border-1${borderSelected === 'sm' ? '--active' : ''}.svg`)" />
                      </v-card>
                      <v-card class="text-center align-center pt-2 pb-1 px-0 rounded-0 transparent" flat @click="borderSelected = 'md'" :class="{'primary' : borderSelected === 'md'}" width="50" :height="34">
                        <img :src="require(`@/assets/product-outbound/portal/border-2${borderSelected === 'md' ? '--active' : ''}.svg`)" />
                      </v-card>
                      <v-card class="text-center align-center pt-2 pb-1 px-0 rounded-0 transparent" flat @click="borderSelected = 'lg'" :class="{'primary rounded-r-sm' : borderSelected === 'lg'}" width="50" :height="34">
                        <img :src="require(`@/assets/product-outbound/portal/border-3${borderSelected === 'lg' ? '--active' : ''}.svg`)" />
                      </v-card>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col class="pt-0 pl-0">
                  <span class="d-block body-1 mb-2">Alineación</span>
                  <v-card class="rounded-sm pa-0 ma-0" flat outlined width="152">
                    <v-row class="fill-height ma-0 pa-0" align="center">
                      <v-card class="text-center align-center pt-2 pb-1 px-0 rounded-0 transparent" flat @click="alignSelected = 'left'" :class="{'primary rounded-l-sm' : alignSelected === 'left'}" width="50" :height="34">
                        <img :src="require(`@/assets/product-outbound/portal/align-1${alignSelected === 'left' ? '--active' : ''}.svg`)" />
                      </v-card>
                      <v-card class="text-center align-center pt-2 pb-1 px-0 rounded-0 transparent" flat @click="alignSelected = 'center'" :class="{'primary' : alignSelected === 'center'}" width="50" :height="34">
                        <img :src="require(`@/assets/product-outbound/portal/align-2${alignSelected === 'center' ? '--active' : ''}.svg`)" />
                      </v-card>
                      <v-card class="text-center align-center pt-2 pb-1 px-0 rounded-0 transparent" flat @click="alignSelected = 'right'" :class="{'primary rounded-r-sm' : alignSelected === 'right'}" width="50" :height="34">
                        <img :src="require(`@/assets/product-outbound/portal/align-3${alignSelected === 'right' ? '--active' : ''}.svg`)" />
                      </v-card>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-5 py-2">
              <v-spacer />
              <v-btn class="px-4" outlined>Cancelar</v-btn>
              <v-btn class="px-4" color="primary">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div class="ml-5" style="flex: 1; position: relative;">
          <v-col cols="6" class="pa-0 pr-15" style="position: fixed;">
            <v-card class="mb-16" flat height="calc(100% - 600px)" :width="$store.state.base.isExpandNavigationDrawer ? 'calc(100% - 82px)' : 'auto'">
              <v-toolbar color="transparent" flat height="46">
                <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
                  <v-row align="center" no-gutters>
                    <v-icon size="18" left color="secondary">mdi-eye</v-icon>
                    Previsualización
                  </v-row>
                </v-toolbar-title>
              </v-toolbar>
              <v-divider />
              <v-card-text class="pa-0 py-5 overflow-y-auto" :style="`max-height: ${$vuetify.breakpoint.height <= 720 ? '460px' : 'calc(100vh - 260px)'} !important;`">
                <ModelTemplates
                  :currentTemplate="$route.params.id"
                  :logo="logo"
                  :image="image"
                  :btn="btn"
                  :text="text"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </div>
      </div>
    </v-col>
    <div class="bgSearch mt-6 pa-0 pagination-footer">
      <v-divider />
      <v-col class="px-0">
        <v-row>
          <v-col cols="6" class="text-left" :style="$store.state.base.isExpandNavigationDrawer ? 'padding-left: 250px' : 'padding-left: 90px'">
            <v-btn
              class="font-weight-semibold"
              :to="{name: 'TemplateList'}"
              color="primary"
              text
            >Cancelar</v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="6" class="text-end">
            <v-btn
              class="px-4 ml-2 mr-6"
              :to="{name: 'ShippingsSettingsTemplate'}"
              color="primary"
            >
              Ajustes de envio
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </v-row>
</template>
<script>
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import DocumentTypes from '@/collections/documentTypes'
import ModelTemplates from '@/modules/outbound/templates/components/templates/Template1'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
    VEmptyState,
    MiniHeader,
    ModelTemplates
  },
  data: () => ({
    logo: {
      hiddenLogo: false,
      color: '#FFFFFF',
      file: null
    },
    image: {
      hiddenBorder: false,
      borderWidth: '#FFFFFF',
      borderRadius: '',
      borderColor: '#FFFFFF'
    },
    text: {
      fontColorTitle: '#000000',
      fontColorSubtitle: '#000000',
      fontColorParagraph: '#000000'
    },
    btn: {
      fontFamily: '',
      fontColor: '#FFFFF',
      hiddenBorder: false,
      hiddenBackgroundColor: false,
      backgroundColor: '#0352CC',
      borderWidth: '',
      borderRadius: '',
      borderColor: '#FFFFFF',
      align: ''
    },
    hiddenBorder: false,
    hiddenRelleno: false,
    lengthList: ['10', '11', '12', '13', '14', '15', '16', '18', '20', '24', '32', '36', '40', '48', '64', '96', '128'],
    recaptcha: null,
    title: '',
    description: '',
    hiddenTitle: false,
    hiddenDescription: false,
    layout: 'col',
    documentTypesList: DocumentTypes,
    date: null,
    datePickerMenu: false,
    borderSelected: 'md',
    alignSelected: 'center',
    itemList: [{text: '', link: ''}],
    menu: false,
    menuFontColorSubtitle: false,
    menuFontColorTitle: false,
    menuFontColorParagraph: false,
    btnMenuFontColor: false,
    menu6: false,
    menu7: false,
    menu8: false,
    menuBtnFontColor: false,
    menuBtnBackgroundColor: false,
    color1: '#FFFFFF',
    color2: '#FFFFFF',
    color3: '#FFFFFF',
    color4: '#FFFFFF',
    color6: '#FFFFFF',
    color8: '#FFFFFF',
    logoPreview: 'https://app-demo.axteroid.com/static/assets/logo-inkafarma.jpg',
    breadcrumbs: {
     main: 'Plantillas',
     children: [{ text: 'Nueva plantilla de email' }, { text1: 'Personalización'}]
   }
  }),
  computed: {
    logStyle () {
      return {
        backgroundColor: this.logo.color,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    swatchStyle1 () {
      return {
        backgroundColor: this.color1,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    swatchStyleFontColorTitle () {
      return {
        backgroundColor: this.text.fontColorTitle,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    swatchStyleFontColorSubtitle () {
      return {
        backgroundColor: this.text.fontColorSubtitle,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    swatchStyleFontColorParagraph () {
      return {
        backgroundColor: this.text.fontColorParagraph,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    swatchStyle3 () {
      return {
        backgroundColor: this.color3,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    swatchStyle4 () {
      return {
        backgroundColor: this.color4,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    btnFontColor () {
      return {
        backgroundColor: this.btn.fontColor,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    swatchStyleBtnBorderColor () {
      return {
        backgroundColor: this.btn.borderColor,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    swatchStyleBtnBackgroundColor () {
      return {
        backgroundColor: this.btn.backgroundColor,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    },
    swatchImage () {
      return {
        backgroundColor: this.image.borderColor,
        cursor: 'pointer',
        height: '20px',
        width: '20px',
        borderRadius: '3px'
      }
    }
  },
  methods: {
    onVerify (response) {
      this.recaptcha = response
    },
    getLogoPreview (e) {
      if (e.target?.files?.length) {
        this.logoPreview = URL.createObjectURL(e.target.files[0])
      } else {
        this.logoPreview = ''
      }
    },
    getLogoPreview1 (e) {
      if (e.target?.files?.length) {
        this.logo.file = URL.createObjectURL(e.target.files[0])
      } else {
        this.logo.file = ''
      }
    },
    resetLogoField () {
      this.$refs.photo.type = 'text'
      this.$refs.photo.type = 'file'
      this.logoPreview = ''
      this.photo = null
    },
    addRowItem () {
      this.itemList.push({text: '', link: ''})
    }
  }
}
</script>
<style scoped>
.upload-logo.v-sheet--outlined {
  border-radius: 10px;
  border: 1px solid var(--light-blue-grey);
  border-style: dashed;
}
</style>